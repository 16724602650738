<template>
  <!-- Footer-->
  <footer class="footer bg-dark pt-5">
    <div class="container pb-2">
      <div class="row align-items-center pb-4">
        <div class="col-md-6 col-xl-5">
          <!-- Links-->
          <div class="row">
            <div class="col">
              <p><img src="/img/logo/logo-stash.svg" /></p>
              <p>
                AWS Stash is built with
                <a href="https://vuejs.org/" target="_blank">Vue.js</a>,
                <a href="https://aws.amazon.com/cdk/" target="_blank"
                  >AWS Cloud Development Kit</a
                >, and powered by
                <a href="https://aws.amazon.com/" target="_blank">AWS</a>. Oh,
                and lots of
                <i
                  class="fa-thin fa-heart fa-lg text-primary fa-flip p-1"
                  style="--fa-animation-duration: 5s;"
                ></i>
                too.
              </p>
              <p>
                By using AWS Stash you agree to be bound by the
                <a href="https://www.youtube.com/t/terms" target="_blank"
                  >YouTube Terms of Service</a
                >
                and the
                <a href="http://www.google.com/policies/privacy" target="_blank"
                  >Google Privacy Policy</a
                >.
              </p>
            </div>
          </div>
          <!-- Socials-->
          <div class="text-nowrap border-top border-light py-4">
            <a
              class="btn btn-icon btn-translucent-light btn-xs rounded-circle me-2"
              href="https://www.linkedin.com/in/nickragusa/"
              target="_blank"
              ><i class="fi-linkedin"></i></a
            ><a
              class="btn btn-icon btn-translucent-light btn-xs rounded-circle me-2"
              href="https://twitter.com/nickjragusa"
              target="_blank"
              ><i class="fi-twitter"></i
            ></a>
            <a
              class="btn btn-icon btn-translucent-light btn-xs rounded-circle me-2"
              href="https://medium.com/@nragusa"
              target="_blank"
              ><i class="fi-medium"></i
            ></a>
          </div>
        </div>
        <!-- Our story -->
        <div class="col-md-6 offset-xl-1">
          <div class="d-flex align-items-center">
            <div
              class="card card-light card-body p-4 p-xl-5 my-2 my-md-0"
              style="max-width: 526px;"
            >
              <div style="max-width: 380px;">
                <h3 class="h4 text-light">Our Story</h3>
                <p class="fs-sm text-light opacity-70 mb-2 mb-lg-3">
                  What once started as a project to aggregate AWS re:Invent
                  videos into a single place, reinventvideos.com has grown up
                  and has added much more content including AWS GitHub
                  repositories, new feature / release announcements, and YouTube
                  videos from additional AWS events. Hopefully you'll find what
                  you're looking for, and keep checking back as the site is
                  updated daily.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Copyright-->
      <p class="fs-sm text-center text-sm-start mb-4">
        <span class="text-light opacity-50"
          >&copy; 2022 All rights reserved.
        </span>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>
<style scoped>
.footer {
  background: url('/public/img/bg-footer.jpeg');
}
</style>
