<template>
  <div class="col">
    <a
      class="icon-box card card-body h-100 border-0 shadow-sm card-hover text-center"
      :class="hoverColor"
      href=""
      @click.prevent
    >
      <div
        class="icon-box-media rounded-circle mb-3 mx-auto"
        :class="iconColor"
      >
        <i :class="iconType"></i>
      </div>
      <h3 class="icon-box-title fs-base mb-0">
        {{ name }}
      </h3></a
    >
  </div>
</template>

<script>
import config from '@/config.js'
export default {
  name: 'ContentFilterButton',
  props: {
    name: String,
    selected: Boolean
  },
  computed: {
    iconColor() {
      return [[config[this.name].color, config[this.name].bg_faded]]
    },
    hoverColor() {
      return { [config[this.name].bg_faded]: this.selected }
    },
    iconType() {
      return { [config[this.name].icon]: true }
    }
  }
}
</script>
