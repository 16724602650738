<template>
  <div>
    <!-- Top Navbar -->
    <Navbar />

    <!-- Search Help Modal -->
    <div id="searchHelp" class="modal fade" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Search Help</h5>
            <span class="fa-duotone fa-magnifying-glass ms-2"></span>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            Become an AWS Stash search ninja with some of these helpful query
            tips!
            <!-- Basic table -->
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>Search Type</th>
                    <th>Examples</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="align-middle" rowspan="3">Boolean</td>
                    <td>simon NOT elisha</td>
                  </tr>
                  <tr>
                    <td>codepipeline OR codebuild</td>
                  </tr>
                  <tr>
                    <td>(ecs AND ecr) NOT eks</td>
                  </tr>
                  <tr>
                    <td class="align-middle" rowspan="2">Wildcard</td>
                    <td>Cloud*</td>
                  </tr>
                  <tr>
                    <td>kendra*aws*</td>
                  </tr>
                  <tr>
                    <td class="align-middle" rowspan="7">Fields</td>
                    <td>tags:mobile</td>
                  </tr>
                  <tr>
                    <td>published_year:2022</td>
                  </tr>
                  <tr>
                    <td>
                      _last_updated_at:&gt;2019-12-31 AND
                      _last_updated_at:&lt;2021-01-01
                    </td>
                  </tr>
                  <tr>
                    <td>_document_title:"Introducing+AWS+CloudFormation"</td>
                  </tr>
                  <tr>
                    <td>level:&lt;300</td>
                  </tr>
                  <tr>
                    <td>likes:&gt;1200</td>
                  </tr>
                  <tr>
                    <td>_authors:"Jeff Barr"</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer border-top-0">
            <button
              type="button"
              class="btn btn-primary btn-sm"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Search bar -->
    <SearchBar :search_text.sync="search_text" />

    <!-- Filter and search results -->
    <section
      class="position-relative bg-white rounded-xxl-4 zindex-5"
      style="margin-top: -30px;"
    >
      <div class="row g-0 mt-n3">
        <!-- Filters sidebar (Offcanvas on mobile)-->
        <aside
          class="col-lg-4 col-xl-3 border-end-lg shadow-sm px-3 px-xl-4 px-xxl-5 pt-lg-2"
        >
          <div
            class="offcanvas offcanvas-start offcanvas-collapse"
            id="filters-sidebar"
          >
            <div class="offcanvas-header d-flex d-lg-none align-items-center">
              <h2 class="h5 mb-0">Filters</h2>
              <button
                class="btn-close"
                type="button"
                data-bs-dismiss="offcanvas"
              ></button>
            </div>
            <!-- Content | Filter buttons -->
            <div
              class="offcanvas-header d-block border-bottom py-lg-4 py-3 px-lg-0"
            >
              <ul class="nav nav-pills" role="tablist">
                <li class="nav-item">
                  <a
                    class="nav-link d-flex align-items-center"
                    :class="content_class"
                    href="#content"
                    data-bs-toggle="tab"
                    role="tab"
                    aria-selected="true"
                    @click="showMenu('content')"
                    ><i class="fi-list me-2"></i>Content</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link d-flex align-items-center"
                    :class="filter_class"
                    href="#filters"
                    data-bs-toggle="tab"
                    role="tab"
                    aria-selected="false"
                    @click="showMenu('filter')"
                    ><i class="fi-filter-alt-horizontal me-2"></i>Filters</a
                  >
                </li>
              </ul>
            </div>
            <!-- Content and Filters -->
            <div class="offcanvas-body py-lg-4">
              <!-- Tabs content-->
              <div class="tab-content">
                <!-- Content-->
                <div
                  class="tab-pane fade"
                  :class="content_class"
                  id="content"
                  role="tabpanel"
                >
                  <div class="row row-cols-lg-2 row-cols-1 g-3">
                    <!-- A Cloud Guru Button -->
                    <content-filter-button
                      name="A Cloud Guru"
                      :selected="category.includes('A Cloud Guru')"
                      @click.native="
                        category = ['A Cloud Guru']
                        search()
                      "
                    />
                    <!-- AWS Blog Button -->
                    <content-filter-button
                      name="AWS Blog"
                      :selected="category.includes('AWS Blog')"
                      @click.native="
                        category = ['AWS Blog']
                        search()
                      "
                    />
                    <!-- AWS Quick Start -->
                    <content-filter-button
                      name="AWS Quick Start"
                      :selected="category.includes('AWS Quick Start')"
                      @click.native="
                        category = ['AWS Quick Start']
                        search()
                      "
                    />
                    <!-- AWS Skill Builder Button -->
                    <content-filter-button
                      name="AWS Skill Builder"
                      :selected="category.includes('AWS Skill Builder')"
                      @click.native="
                        category = ['AWS Skill Builder']
                        search()
                      "
                    />
                    <!-- AWS Solutions Button -->
                    <content-filter-button
                      name="AWS Solutions"
                      :selected="category.includes('AWS Solutions')"
                      @click.native="
                        category = ['AWS Solutions']
                        search()
                      "
                    />
                    <!-- AWS Slides Button -->
                    <content-filter-button
                      name="AWS Slides"
                      :selected="category.includes('AWS Slides')"
                      @click.native="
                        category = ['AWS Slides']
                        search()
                      "
                    />
                    <!-- AWS Tech Talk Button -->
                    <content-filter-button
                      name="AWS Tech Talk"
                      :selected="category.includes('AWS Tech Talk')"
                      @click.native="
                        category = ['AWS Tech Talk']
                        search()
                      "
                    />
                    <!-- AWS Whitepapers Button -->
                    <content-filter-button
                      name="AWS Whitepapers"
                      :selected="category.includes('AWS Whitepapers')"
                      @click.native="
                        category = ['AWS Whitepapers']
                        search()
                      "
                    />
                    <!-- GitHub Button -->
                    <content-filter-button
                      name="GitHub"
                      :selected="category.includes('GitHub')"
                      @click.native="
                        category = ['GitHub']
                        search()
                      "
                    />
                    <!-- Launch Announcement Button -->
                    <content-filter-button
                      name="Launch Announcement"
                      :selected="category.includes('Launch Announcement')"
                      @click.native="
                        category = ['Launch Announcement']
                        search()
                      "
                    />
                    <!-- Podcasts Button -->
                    <content-filter-button
                      name="Podcast"
                      :selected="category.includes('Podcast')"
                      @click.native="
                        category = ['Podcast']
                        search()
                      "
                    />
                    <!-- Prescriptive Guidance Button -->
                    <content-filter-button
                      name="Prescriptive Guidance"
                      :selected="category.includes('Prescriptive Guidance')"
                      @click.native="
                        category = ['Prescriptive Guidance']
                        search()
                      "
                    />
                    <!-- Reddit Button -->
                    <content-filter-button
                      name="Reddit"
                      :selected="category.includes('Reddit')"
                      @click.native="
                        category = ['Reddit']
                        search()
                      "
                    />
                    <!-- Twitch Button -->
                    <content-filter-button
                      name="Twitch"
                      :selected="category.includes('Twitch')"
                      @click.native="
                        category = ['Twitch']
                        search()
                      "
                    />
                    <!-- YouTube Button -->
                    <content-filter-button
                      name="YouTube"
                      :selected="category.includes('YouTube')"
                      @click.native="
                        category = ['YouTube']
                        search()
                      "
                    />
                  </div>
                </div>
                <!-- Filters-->
                <div
                  class="tab-pane fade"
                  id="filters"
                  role="tabpanel"
                  :class="filter_class"
                >
                  <!-- Current filter pills -->
                  <div class="pb-4 mb-2">
                    <filter-pill
                      v-for="(filter, index) in comp_filters"
                      :key="index"
                      :filter="filter"
                      @removeFilter="removeFilter"
                    />
                  </div>
                  <!-- FAQ Accordion -->
                  <!-- Accordion basic -->
                  <h6 v-if="show_faq">
                    FAQs
                  </h6>
                  <div
                    v-if="show_faq"
                    class="accordion pb-4 mb-2"
                    id="accordionFAQ"
                  >
                    <!-- Accordion item -->
                    <FAQAccordion
                      v-for="item in faq_results.ResultItems"
                      :key="item.id"
                      :item="item"
                      :index="faq_results.ResultItems.indexOf(item)"
                    ></FAQAccordion>
                  </div>
                  <!-- Content filter -->
                  <div class="pb-4 mb-2">
                    <h6>Content</h6>
                    <simplebar
                      data-simplebar-auto-hide="false"
                      style="height: 11rem;"
                    >
                      <div
                        v-for="content in Object.keys(facets._category).sort()"
                        :key="content"
                        class="form-check"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          :value="content"
                          :id="content"
                          v-model="category"
                          @change="search()"
                        />
                        <label class="form-check-label fs-sm" :for="content"
                          >{{ content }}
                          <span
                            class="badge rounded-pill bg-faded-primary"
                            v-if="facets._category[content]"
                          >
                            {{ facets._category[content] }}</span
                          ></label
                        >
                      </div>
                    </simplebar>
                  </div>
                  <!-- Year filter -->
                  <div class="pb-4 mb-2">
                    <h3 class="h6">Year</h3>
                    <simplebar
                      data-simplebar-auto-hide="false"
                      style="height: 11rem;"
                    >
                      <div
                        v-for="year in Object.keys(
                          facets.published_year
                        ).reverse()"
                        :key="year"
                        class="form-check"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          :value="year"
                          :id="year"
                          v-model.number="published_year"
                          @change="search()"
                        />
                        <label class="form-check-label fs-sm" :for="year"
                          >{{ year }}
                          <span
                            class="badge rounded-pill bg-faded-primary"
                            v-if="facets.published_year[year]"
                            >{{ facets.published_year[year] }}</span
                          ></label
                        >
                      </div>
                    </simplebar>
                  </div>
                  <!-- Event filter -->
                  <div class="pb-4 mb-2">
                    <h3 class="h6">Event</h3>
                    <simplebar data-simplebar-auto-hide="false">
                      <div
                        v-for="this_event in sorted_events()"
                        :key="this_event"
                        class="form-check"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          :value="this_event"
                          :id="this_event"
                          v-model="event"
                          @change="search()"
                        />
                        <label class="form-check-label fs-sm" :for="this_event"
                          >{{ this_event }}
                          <span
                            class="badge rounded-pill bg-faded-primary"
                            v-if="facets.event[this_event]"
                            >{{ facets.event[this_event] }}</span
                          ></label
                        >
                      </div>
                    </simplebar>
                  </div>
                  <div class="pb-4 mb-2">
                    <!-- Series filter -->
                    <h3 class="h6">Series</h3>
                    <simplebar data-simplebar-auto-hide="false">
                      <div
                        v-for="this_series in sorted_series()"
                        :key="this_series"
                        class="form-check"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          :value="this_series"
                          :id="this_series"
                          v-model="series"
                          @change="search()"
                        />
                        <label class="form-check-label fs-sm" :for="this_series"
                          >{{ this_series }}
                          <span
                            class="badge rounded-pill bg-faded-primary"
                            v-if="facets.series[this_series]"
                            >{{ facets.series[this_series] }}</span
                          ></label
                        >
                      </div>
                    </simplebar>
                  </div>
                  <div class="pb-4 mb-2">
                    <!-- Level -->
                    <h3 class="h6">Level</h3>
                    <simplebar data-simplebar-auto-hide="false">
                      <div
                        v-for="this_level in sorted_level()"
                        :key="this_level"
                        class="form-check"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          :value="this_level"
                          :id="this_level"
                          v-model="level"
                          @change="search()"
                        />
                        <label class="form-check-label fs-sm" :for="this_level"
                          >{{ this_level }}
                          <span
                            class="badge rounded-pill bg-faded-primary"
                            v-if="facets.level[this_level]"
                            >{{ facets.level[this_level] }}</span
                          ></label
                        >
                      </div>
                    </simplebar>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </aside>

        <!-- Content  -->
        <!-- Page content-->
        <div
          class="col-lg-8 col-xl-9 position-relative overflow-hidden pb-5 pt-4 px-3 px-xl-4 px-xxl-5"
        >
          <!-- Title-->
          <div
            class="d-sm-flex align-items-center justify-content-between pb-3 pb-sm-4"
          ></div>
          <!-- Sorting-->
          <div
            class="d-flex flex-row align-items-sm-center align-items-stretch my-2"
          >
            <div class="d-flex align-items-center flex-shrink-0">
              <label class="fs-sm me-2 pe-1 text-nowrap" for="sortby"
                ><a
                  href="#"
                  v-on:click="toggle()"
                  v-show="this.sort != 'relevance'"
                  ><i
                    v-if="sort_order == 'ASC'"
                    class="fi-chevron-up text-muted mt-n1 me-2"
                  ></i
                  ><i v-else class="fi-chevron-down text-muted mt-n1 me-2"></i>
                </a>
                Sort by:</label
              >
              <select
                class="form-select form-select-sm"
                id="sortby"
                @change="search()"
                v-model="sort"
              >
                <option v-if="search_text != ''" value="relevance"
                  >Relevance</option
                >
                <option value="_last_updated_at">Last Updated</option>
                <option value="views">Views</option>
                <option value="likes">Likes</option>
              </select>
            </div>
            <div class="d-none d-sm-flex align-items-center flex-shrink-0 ps-3">
              <label class="fs-sm me-2 pe-1 text-nowrap" for="pagesize"
                >Results:</label
              >
              <select
                class="form-select form-select-sm"
                id="pagesize"
                @change="search()"
                v-model="page_size"
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
            <hr class="d-none d-sm-block w-100 mx-4" />
            <div
              class="d-none d-sm-flex align-items-center flex-shrink-0 text-muted"
            >
              <i class="fi-check-circle me-2"></i
              ><span class="fs-sm mt-n1"
                >{{ results.TotalResults }} results</span
              >
            </div>
          </div>
          <!-- Top Pagination-->
          <div
            class="d-flex flex-sm-row flex-column justify-content-center my-2"
            :class="results_visibility"
            v-if="results.TotalResults != 0"
          >
            <nav class="pb-md-4 pt-4" aria-label="Pagination">
              <ul class="pagination mb-1 justify-content-center">
                <!-- Jump to beginning chevron  -->
                <li
                  v-if="page === 1"
                  class="page-item d-none d-sm-block disabled"
                >
                  <a class="page-link" aria-label="Previous"
                    ><i class="fi-chevrons-left"></i
                  ></a>
                </li>
                <li v-else class="page-item d-none d-sm-block">
                  <a
                    href=""
                    class="page-link"
                    aria-label="Previous"
                    @click.prevent="search(1)"
                    ><i class="fi-chevrons-left"></i
                  ></a>
                </li>
                <!-- Back 1 page chevron  -->
                <li
                  v-if="page === 1"
                  class="page-item d-none d-sm-block disabled"
                >
                  <a class="page-link" aria-label="Previous"
                    ><i class="fi-chevron-left"></i
                  ></a>
                </li>

                <li v-else class="page-item d-none d-sm-block">
                  <a
                    href=""
                    class="page-link"
                    aria-label="Previous"
                    @click.prevent="search(page - 1)"
                    ><i class="fi-chevron-left"></i
                  ></a>
                </li>
                <!-- Pages -->
                <div v-for="p of pages" :key="p">
                  <li
                    v-if="p === page"
                    class="page-item d-none d-sm-block active"
                    aria-label="Current"
                  >
                    <span class="page-link">{{ p }}</span>
                  </li>
                  <li v-else class="page-item d-none d-sm-block">
                    <a href="" class="page-link" @click.prevent="search(p)">{{
                      p
                    }}</a>
                  </li>
                </div>
                <!-- Forward 1 page chevron -->
                <li
                  v-if="page === total_pages"
                  class="page-item d-none d-sm-block disabled"
                >
                  <a class="page-link" aria-label="Next"
                    ><i class="fi-chevron-right"></i
                  ></a>
                </li>
                <li v-else class="page-item d-none d-sm-block">
                  <a
                    href=""
                    class="page-link"
                    aria-label="Next"
                    @click.prevent="search(page + 1)"
                    ><i class="fi-chevron-right"></i
                  ></a>
                </li>
                <!-- Jump to end chevrons -->
                <li
                  v-if="page === total_pages"
                  class="page-item d-none d-sm-block disabled"
                >
                  <a class="page-link" aria-label="Next"
                    ><i class="fi-chevrons-right"></i
                  ></a>
                </li>
                <li v-else class="page-item d-none d-sm-block">
                  <a
                    href=""
                    class="page-link"
                    aria-label="Next"
                    @click.prevent="search(total_pages)"
                    ><i class="fi-chevrons-right"></i
                  ></a>
                </li>
              </ul>
            </nav>
          </div>
          <!-- Catalog grid-->
          <!-- Loading spinner -->
          <div
            v-if="spinner_visibility"
            class="container-fluid h-10 d-flex flex-column"
          >
            <div class="row flex-grow-1 align-items-center">
              <div class="col text-center">
                <div class="fa-duotone fa-loader fa-fade fa-5x" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="results.TotalResults == 0"
            class="d-flex flex-sm-row justify-content-center"
            :class="results_visibility"
          >
            <span v-if="error_message == ''">
              <p class="lead">
                No results
              </p>
            </span>
            <span v-else>
              <p class="lead">
                {{ error_message }}
              </p>
            </span>
          </div>
          <div
            class="row row-cols-xl-3 row-cols-sm-2 row-cols-1 gy-4 gx-3 gx-xxl-4 py-4"
            :class="results_visibility"
          >
            <!-- Item -->
            <ContentCard
              v-for="item in results.ResultItems"
              :key="item.id"
              :item="item"
              :query="results.QueryId"
              @add_tag="addTag"
              @show_toast="showToast"
            ></ContentCard>
          </div>
          <!-- Bottom Pagination-->
          <nav
            class="pb-md-4 pt-4"
            aria-label="Pagination"
            :class="results_visibility"
            v-if="results.TotalResults != 0"
          >
            <ul class="pagination mb-1 justify-content-center">
              <!-- Jump to beginning chevron  -->
              <li v-if="page === 1" class="page-item d-block disabled">
                <a class="page-link" aria-label="Previous"
                  ><i class="fi-chevrons-left"></i
                ></a>
              </li>
              <li v-else class="page-item d-block">
                <a
                  href=""
                  class="page-link"
                  aria-label="Previous"
                  @click.prevent="search(1)"
                  ><i class="fi-chevrons-left"></i
                ></a>
              </li>
              <!-- Back 1 page chevron  -->
              <li v-if="page === 1" class="page-item d-block disabled">
                <a class="page-link" aria-label="Previous"
                  ><i class="fi-chevron-left"></i
                ></a>
              </li>

              <li v-else class="page-item d-block">
                <a
                  href=""
                  class="page-link"
                  aria-label="Previous"
                  @click.prevent="search(page - 1)"
                  ><i class="fi-chevron-left"></i
                ></a>
              </li>
              <!-- Pages -->
              <div v-for="p of pages" :key="p">
                <li
                  v-if="p === page"
                  class="page-item d-block active"
                  aria-label="Current"
                >
                  <span class="page-link">{{ p }}</span>
                </li>
                <li v-else class="page-item d-block">
                  <a href="" class="page-link" @click.prevent="search(p)">{{
                    p
                  }}</a>
                </li>
              </div>
              <!-- Forward 1 page chevron -->
              <li
                v-if="page === total_pages"
                class="page-item d-block disabled"
              >
                <a class="page-link" aria-label="Next"
                  ><i class="fi-chevron-right"></i
                ></a>
              </li>
              <li v-else class="page-item d-block">
                <a
                  href=""
                  class="page-link"
                  aria-label="Next"
                  @click.prevent="search(page + 1)"
                  ><i class="fi-chevron-right"></i
                ></a>
              </li>
              <!-- Jump to end chevrons -->
              <li
                v-if="page === total_pages"
                class="page-item d-block disabled"
              >
                <a class="page-link" aria-label="Next"
                  ><i class="fi-chevrons-right"></i
                ></a>
              </li>
              <li v-else class="page-item d-block">
                <a
                  href=""
                  class="page-link"
                  aria-label="Next"
                  @click.prevent="search(total_pages)"
                  ><i class="fi-chevrons-right"></i
                ></a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div
        class="toast-container position-fixed bottom-0 start-0 p-3"
        style="z-index: 1;"
      >
        <div
          class="toast fade"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
          :class="show_class"
        >
          <div class="toast-header bg-primary text-white">
            <i class="fi-bell me-2"></i>
            <span class="fw-bold me-auto">Feedback submitted</span>
            <button
              type="button"
              class="btn-close btn-close-white text-white ms-2"
              data-bs-dismiss="toast"
              aria-label="Close"
            ></button>
          </div>
          <div
            class="toast-body"
            style="background-color: rgba(255,255,255,1);"
          >
            Your feedback has been submitted and will help improve search
            results going forward.
          </div>
        </div>
      </div>
    </section>
    <Footer />
    <!-- Filter button on mobile -->
    <button
      class="btn btn-primary btn-sm w-100 rounded-0 fixed-bottom d-lg-none"
      type="button"
      data-bs-toggle="offcanvas"
      data-bs-target="#filters-sidebar"
    >
      <i class="fi-filter me-2"></i>Filters
    </button>
  </div>
</template>

<script>
import Navbar from '@/views/Navbar.vue'
import SearchBar from '@/views/SearchBar.vue'
import ContentCard from '@/components/ContentCard.vue'
import FAQAccordion from '@/components/FAQAccordion.vue'
import Footer from '@/views/Footer.vue'
import ContentFilterButton from '@/components/ContentFilterButton.vue'
import FilterPill from '@/components/FilterPill.vue'
import KendraService from '@/services/KendraService.js'
import { debounce, range, pull, concat } from 'lodash'
import simplebar from 'simplebar-vue'

export default {
  name: 'MainContent',
  components: {
    ContentCard,
    FAQAccordion,
    ContentFilterButton,
    FilterPill,
    simplebar,
    Navbar,
    SearchBar,
    Footer
  },
  mounted() {
    this.search()
  },
  methods: {
    addTag(tag) {
      // Gets a tag click from ContentCard child, adds the tag to the
      // array and searches
      if (this.tags.includes(tag)) {
        this.search()
      } else {
        this.tags.push(tag)
        this.search()
      }
    },
    showToast() {
      this.show_class = 'show'
      setTimeout(() => (this.show_class = 'hide'), 5000)
    },
    showMenu(menu) {
      // Toggles between content and filter menu on left
      if (menu == 'content') {
        this.content_class = 'show active'
        this.filter_class = ''
      } else {
        this.content_class = ''
        this.filter_class = 'show active'
      }
    },
    removeFilter(filter) {
      if (filter.filter_type == 'search_text') {
        this.search_text = ''
        if (this.sort == 'relevance') {
          this.sort = '_last_updated_at'
        }
        return
      }
      if (filter.filter_type == 'category') {
        this.category.splice(this.category.indexOf(filter.value), 1)
      }
      if (filter.filter_type == 'tags') {
        this.tags.splice(this.tags.indexOf(filter.value), 1)
      }
      if (filter.filter_type == 'published_year') {
        this.published_year.splice(
          this.published_year.indexOf(parseInt(filter.value)),
          1
        )
      }
      if (filter.filter_type == 'series') {
        this.series.splice(this.series.indexOf(filter.value), 1)
      }
      if (filter.filter_type == 'event') {
        this.event.splice(this.event.indexOf(filter.value), 1)
      }
      if (filter.filter_type == 'level') {
        this.level.splice(this.level.indexOf(filter.value), 1)
      }
      this.search()
    },
    toggle() {
      if (this.sort_order == 'ASC') {
        this.sort_order = 'DESC'
      } else {
        this.sort_order = 'ASC'
      }
      this.search()
    },
    search(page = 1) {
      // Get FAQ results first
      let query = JSON.stringify({
        query_text: this.search_text,
        doc_type: 'QUESTION_ANSWER'
      })
      KendraService.search(query)
        .then(response => {
          this.faq_results = response.data
        })
        .catch(err => {
          console.log(err)
        })
      this.results_visibility = 'invisible'
      this.spinner_visibility = true
      this.kendra_query = JSON.stringify({
        query_text: this.search_text,
        category: this.category,
        published_year: this.published_year,
        level: this.level,
        event: this.event,
        series: this.series,
        tags: this.tags,
        page: page,
        page_size: this.page_size,
        sort: this.sort,
        sort_order: this.sort_order,
        doc_type: 'DOCUMENT'
      })
      this.filters = [this.search_text]
      this.filters.push(...this.category)
      // console.log(`Search query: ${this.kendra_query}`)
      KendraService.search(this.kendra_query)
        .then(response => {
          this.spinner_visibility = false
          this.results_visibility = 'visible'
          this.results = response.data
          this.page = page

          // Figures out the pagination toolbar at the bottom
          if (this.results.TotalResults >= 100) {
            this.total_pages = Math.ceil(100 / this.page_size)
          } else {
            this.total_pages = Math.ceil(
              this.results.TotalResults / this.page_size
            )
          }
          if (this.total_pages <= 3) {
            this.pages = range(1, this.total_pages + 1)
          } else {
            // more than 4 pages returned
            if (this.page === 1) {
              this.pages = range(1, 4)
            } else if (this.page === this.total_pages) {
              this.pages = range(this.total_pages - 2, this.total_pages + 1)
            } else {
              this.pages = range(this.page - 1, this.page + 2)
            }
          }

          // Iterate over returned facets and update facet values and counts
          // response.data.FacetResults = {
          //   event: [{count: 10, value: 're:Invent'}],
          //   _category: [{count: 1950, value: 'Twitch'}]
          // }
          for (let facet in response.data.FacetResults) {
            // Update or add facets and their counts from response
            for (let f of response.data.FacetResults[facet]) {
              if (!(f.value in this.facets[facet])) {
                // e.g. facets['event'] = { 'This Is My Architecture': 27 }
                this.facets[facet] = Object.assign(this.facets[facet], {
                  [f.value]: f.count
                })
              } else {
                this.facets[facet][f.value] = f.count
              }
            }
          }

          // If facet values and counts were not returned but we have them from
          // a previous query, set their count value to None
          for (let facet in this.facets) {
            if (facet in response.data.FacetResults) {
              for (let [facet_value] of Object.entries(this.facets[facet])) {
                if (
                  !response.data.FacetResults[facet].some(
                    f => f.value === facet_value
                  )
                ) {
                  // Sets this count of this facet to ''
                  this.facets[facet][facet_value] = ''
                }
              }
            }
          }
        })
        .catch(err => {
          this.results.TotalResults = 0
          this.spinner_visibility = false
          this.results_visibility = 'visible'
          this.error_message = err.response.data.error
        })
    },
    sorted_events() {
      return concat(pull(Object.keys(this.facets.event).sort(), 'N/A'), 'N/A')
    },
    sorted_series() {
      return concat(pull(Object.keys(this.facets.series).sort(), 'N/A'), 'N/A')
    },
    sorted_level() {
      return concat(pull(Object.keys(this.facets.level).sort(), 'N/A'), 'N/A')
    }
  },
  created() {
    this.debounceName = debounce(this.search, 1000)
  },
  watch: {
    search_text() {
      if (this.search_text.length == 0) {
        if (this.sort == 'relevance') {
          this.sort = '_last_updated_at'
        }
        this.debounceName()
      } else {
        /* If search_text changes, default to most relevant sort
           and show the filters menu on left 
        */
        this.sort = 'relevance'
        this.showMenu('filter')
        this.debounceName()
      }
    }
  },
  computed: {
    comp_filters() {
      // Returns the filters above the facets which can be quickly removed
      let f = []
      if (this.search_text.length > 0) {
        f.push({ filter_type: 'search_text', value: this.search_text })
      }
      if (this.category.length > 0) {
        for (let c of this.category) {
          f.push({ filter_type: 'category', value: c })
        }
      }
      if (this.published_year.length > 0) {
        for (let p of this.published_year) {
          f.push({
            filter_type: 'published_year',
            value: p
          })
        }
      }
      if (this.level.length > 0) {
        for (let l of this.level) {
          f.push({ filter_type: 'level', value: l })
        }
      }
      if (this.event.length > 0) {
        for (let e of this.event) {
          f.push({ filter_type: 'event', value: e })
        }
      }
      if (this.series.length > 0) {
        for (let s of this.series) {
          f.push({ filter_type: 'series', value: s })
        }
      }
      if (this.tags.length > 0) {
        for (let t of this.tags) {
          f.push({ filter_type: 'tags', value: t })
        }
      }
      return f
    },
    show_faq() {
      if ('ResultItems' in this.faq_results) {
        if (this.faq_results.ResultItems.length > 0) {
          return true
        }
      }
      return false
    }
  },
  data() {
    return {
      kendra_query: {},
      search_text: '',
      sort: '_last_updated_at',
      sort_order: 'DESC',
      error_message: '',
      results: {},
      faq_results: {},
      results_visibility: 'visible',
      spinner_visibility: false,
      // Used for creating a query
      category: [],
      published_year: [],
      level: [],
      event: [],
      series: [],
      tags: [],
      // Used for displaying facet values and counts
      facets: {
        _category: {},
        published_year: {},
        event: {},
        level: {},
        series: {}
      },
      filters: [],
      page: 1,
      page_size: 10,
      pages: [1, 2, 3],
      total_pages: 10,
      show_class: 'hide',
      content_class: 'active show',
      filter_class: ''
    }
  }
}
</script>
