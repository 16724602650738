<template>
  <section class="bg-dark pt-5 navbar-bg">
    <div class="container py-5">
      <!-- Search form-->
      <div class="row justify-content-center">
        <div class="col-lg-6 col-sm-10 col-xs-12">
          <form
            class="form-group d-block rounded-lg-pill mb-4"
            v-on:submit.prevent
          >
            <div class="row align-items-center g-0 ms-n2">
              <div class="col">
                <div
                  class="input-group input-group-lg border-end-lg border-light"
                >
                  <span class="input-group-text rounded-pill opacity-50 ps-3"
                    ><i class="fa-duotone fa-magnifying-glass"></i
                  ></span>
                  <input
                    class="form-control"
                    type="text"
                    id="search"
                    :value="search_text"
                    @input="$emit('update:search_text', $event.target.value)"
                    autofocus
                  />
                </div>
              </div>
            </div>
          </form>
          <p id="typing" class="text-center lead text-light"></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import TypeIt from 'typeit'
import config from '@/config.js'
export default {
  name: 'SearchBar',
  props: {
    search_text: String
  },
  mounted() {
    // Shows example searches in the input text
    // eslint-disable-next-line no-undef
    new TypeIt('#typing', {
      strings: config.example_search_text,
      waitUntilVisible: true,
      speed: 250,
      cursorChar: '<span class="ms-2 text-primary fa-solid fa-pipe"></span>',
      breakLines: false,
      nextStringDelay: [3000, 2000],
      loop: false,
      html: true,
      afterComplete: async function() {
        let sleep = ms => new Promise(resolve => setTimeout(resolve, ms))
        await sleep(2000)
        document.getElementById('typing').classList.add('fade')
      }
    }).go()
  }
}
</script>
<style scoped>
.navbar-bg {
  background: url('/public/img/bg-navbar.jpeg');
  background-size: cover;
}
</style>
