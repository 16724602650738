export default {
  'A Cloud Guru': {
    icon: 'fa-thin fa-cloud',
    color: 'text-primary',
    button: 'btn-primary',
    bg_faded: 'bg-faded-primary',
    bg_solid: 'bg-primary',
    launch_text: 'Released'
  },
  'AWS Blog': {
    icon: 'fa-thin fa-blog',
    color: 'text-accent',
    button: 'btn-accent',
    bg_faded: 'bg-faded-accent',
    bg_solid: 'bg-accent',
    launch_text: 'Published'
  },
  'AWS Quick Start': {
    icon: 'fa-thin fa-rabbit-running',
    color: 'text-info',
    button: 'btn-info',
    bg_faded: 'bg-faded-info',
    bg_solid: 'bg-info',
    launch_text: 'Released'
  },
  'AWS Skill Builder': {
    icon: 'fa-thin fa-laptop',
    color: 'text-success',
    button: 'btn-success',
    bg_faded: 'bg-faded-success',
    bg_solid: 'bg-success',
    launch_text: 'Released'
  },
  'AWS Solutions': {
    icon: 'fa-thin fa-code-branch',
    color: 'text-warning',
    button: 'btn-warning',
    bg_faded: 'bg-faded-warning',
    bg_solid: 'bg-warning',
    launch_text: 'Released'
  },
  'AWS Slides': {
    icon: 'fa-thin fa-presentation-screen',
    color: 'text-danger',
    button: 'btn-danger',
    bg_faded: 'bg-faded-danger',
    bg_solid: 'bg-danger',
    launch_text: 'Published'
  },
  'AWS Tech Talk': {
    icon: 'fa-thin fa-comments',
    button: 'btn-dark',
    color: 'text-dark',
    bg_faded: 'bg-faded-dark',
    bg_solid: 'bg-dark',
    launch_text: 'Released'
  },
  'AWS Whitepapers': {
    icon: 'fa-thin fa-file-pdf',
    color: 'text-primary',
    button: 'btn-primary',
    bg_faded: 'bg-faded-primary',
    bg_solid: 'bg-primary',
    launch_text: 'Published'
  },
  GitHub: {
    icon: 'fa-thin fa-code-branch',
    color: 'text-accent',
    button: 'btn-accent',
    bg_faded: 'bg-faded-accent',
    bg_solid: 'bg-accent',
    launch_text: 'Released'
  },
  'Launch Announcement': {
    icon: 'fa-thin fa-rocket-launch',
    color: 'text-info',
    button: 'btn-info',
    bg_faded: 'bg-faded-info',
    bg_solid: 'bg-info',
    launch_text: 'Launched'
  },
  Podcast: {
    icon: 'fa-thin fa-podcast',
    color: 'text-success',
    button: 'btn-success',
    bg_faded: 'bg-faded-success',
    bg_solid: 'bg-success',
    launch_text: 'Recorded'
  },
  'Prescriptive Guidance': {
    icon: 'fa-thin fa-prescription',
    color: 'text-warning',
    button: 'btn-warning',
    bg_faded: 'bg-faded-warning',
    bg_solid: 'bg-warning',
    launch_text: 'Published'
  },
  Reddit: {
    icon: 'fa-brands fa-reddit-alien',
    color: 'text-danger',
    button: 'btn-danger',
    bg_faded: 'bg-faded-danger',
    bg_solid: 'bg-danger',
    launch_text: 'Posted'
  },
  Twitch: {
    icon: 'fa-brands fa-twitch',
    color: 'text-dark',
    button: 'btn-dark',
    bg_faded: 'bg-faded-dark',
    bg_solid: 'bg-dark',
    launch_text: 'Streamed'
  },
  YouTube: {
    icon: 'fa-brands fa-youtube',
    color: 'text-primary',
    button: 'btn-primary',
    bg_faded: 'bg-faded-primary',
    bg_solid: 'bg-primary',
    launch_text: 'Posted'
  },
  example_search_text: [
    'codepipeline OR codebuild',
    'Cloud*',
    'tags:mobile',
    'published_year:2022',
    '_last_updated_at:>2019-12-31',
    'level:<300',
    'Amazon+Connect',
    '_authors:"Jeff Barr"'
  ]
}
