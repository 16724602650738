<template>
  <span class="badge me-2 mt-2" :class="pill_color">
    <span class="fa-thin fa-xmark pe-1" @click="removeFacet"></span>
    {{ filter.value }}</span
  >
</template>

<script>
export default {
  name: 'FilterPill',
  props: {
    filter: Object
  },
  computed: {
    pill_color() {
      if (this.filter.filter_type == 'search_text') {
        return { 'bg-accent': true }
      }
      return { 'bg-primary': true }
    }
  },
  methods: {
    removeFacet() {
      this.$emit('removeFilter', this.filter)
    }
  }
}
</script>
