import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ReadMore from 'vue-read-more'
import VueCookies from 'vue-cookies'
import simplebar from 'simplebar-vue'

Vue.config.productionTip = false
Vue.use(ReadMore)
Vue.use(VueCookies)
Vue.use(simplebar)
Vue.$cookies.config('365d')

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
