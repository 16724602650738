<template>
  <!-- Navbar-->
  <header
    class="navbar navbar-expand navbar-dark fixed-top"
    :class="navbar_class"
    :style="navbar_style"
    data-scroll-header
  >
    <div class="container">
      <a class="navbar-brand me-0 me-xl-4" href="#"
        ><img
          class="d-block"
          src="img/logo/logo-stash.svg"
          width="116"
          alt="Finder"
      /></a>
      <button
        class="navbar-toggler ms-auto"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span></button
      ><a
        class="btn btn-link btn-light btn-sm d-none d-lg-block order-lg-3"
        href="#help"
        data-bs-toggle="modal"
        data-bs-target="#searchHelp"
        >Help</a
      >
      <div class="collapse navbar-collapse order-lg-2" id="navbarNav">
        <ul
          class="navbar-nav navbar-nav-scroll"
          style="max-height: 35rem;"
        ></ul>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Navbar',
  components: {},
  data() {
    return {
      navbar_class: { 'bg-transparent': true },
      navbar_style: {}
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 180) {
        this.navbar_style = {
          background: 'url("/img/bg-navbar.jpeg"',
          'background-size': 'cover'
        }
      } else {
        this.navbar_background = { 'bg-transparent': true }
        this.navbar_style = {}
      }
    }
  }
}
</script>
