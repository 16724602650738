<template>
  <div class="col pb-sm-2">
    <article class="card card-hover border-0 shadow-sm h-100">
      <a
        :href="item.url"
        :data-kendra-token="item.token"
        target="_blank"
        class="card-img-top overflow-hidden"
      >
        <img
          class="d-block mx-auto pb-4"
          :src="item.attributes.thumbnail"
          alt="Image"
          @error="defaultImage"
        />
        <div class="position-absolute end-0 top-0 pt-3 pe-3">
          <span :class="lastUpdatedClass">Updated {{ days_ago }}</span>
        </div>
      </a>
      <div class="card-body p-3">
        <span class="float-end">
          <content-icon :content="item.attributes._category" />
        </span>
        <a
          :href="item.url"
          :data-kendra-token="item.token"
          target="_blank"
          class="text-uppercase text-decoration-none"
          >{{ item.attributes._category }}</a
        >
        <span v-if="item.attributes.series != 'N/A'">
          | {{ item.attributes.series }}</span
        >
        <span v-if="item.attributes.event != 'N/A'">
          | {{ item.attributes.event }}</span
        >
        <h3 class="fs-base pt-1 mb-2">
          <a
            :href="item.url"
            :data-kendra-token="item.token"
            target="_blank"
            class="nav-link"
            >{{ item.title }}</a
          >
        </h3>
        <p
          v-if="item.attributes._authors && item.attributes._authors.length > 0"
        >
          <span>{{ authors }} </span>
        </p>
        <p class="fs-sm m-0">
          {{ item.attributes.description }}
        </p>
      </div>
      <div class="card-footer border-top-0 pt-0 mb-1">
        <div class="border-top border-light pt-1">
          <div class="row g-2">
            <div class="col pb-2">
              <!-- class="badge rounded-pill m-1" -->
              <button
                type="button"
                v-for="tag in tags"
                :key="tag"
                class="btn rounded-pill btn-xs m-1 ps-2 pe-2 pt-1 pb-1"
                :class="[bgSolidColor, bgButton]"
                @click="addTag(tag)"
              >
                <span class="fa-thin fa-hashtag me-1"></span>{{ tag }}
              </button>
            </div>
          </div>
          <div class="row g-2">
            <div class="col me-sm-1">
              <div class="rounded text-center w-100 h-100 p-2">
                <p class="fs-sm mb-1 text-uppercase">{{ launchText }}</p>
                <div
                  class="icon-box-media rounded-circle mx-auto"
                  :class="bgFadedColor"
                >
                  <i class="fa-thin fa-rocket-launch" :class="textColor"></i>
                </div>
                <span class="fs-sm">{{ item.attributes.published_year }}</span>
              </div>
            </div>
            <div
              v-if="
                ['YouTube', 'Reddit', 'GitHub'].includes(
                  item.attributes._category
                )
              "
              class="col me-sm-1"
            >
              <div class="rounded text-center w-100 h-100 p-2">
                <p class="fs-sm mb-1 text-uppercase">Likes</p>
                <div
                  class="icon-box-media rounded-circle mx-auto"
                  :class="bgFadedColor"
                >
                  <i class="fa-thin fa-thumbs-up" :class="textColor"></i>
                </div>
                <span class="fs-sm">{{ item.attributes.likes }}</span>
              </div>
            </div>
            <div
              v-if="['YouTube', 'Twitch'].includes(item.attributes._category)"
              class="col me-sm-1"
            >
              <div class="rounded text-center w-100 h-100 p-2">
                <p class="fs-sm mb-1 text-uppercase">Views</p>
                <div
                  class="icon-box-media rounded-circle mx-auto"
                  :class="bgFadedColor"
                >
                  <i class="fa-thin fa-glasses-round" :class="textColor"></i>
                </div>
                <span class="fs-sm">{{ item.attributes.views }}</span>
              </div>
            </div>
            <div
              v-if="
                [
                  'YouTube',
                  'A Cloud Guru',
                  'AWS Slides',
                  'AWS Tech Talk'
                ].includes(item.attributes._category)
              "
              class="col me-sm-1"
            >
              <div class="rounded text-center w-100 h-100 p-2">
                <p class="fs-sm mb-1 text-uppercase">Level</p>
                <div
                  class="icon-box-media rounded-circle mx-auto"
                  :class="bgFadedColor"
                >
                  <i class="fa-thin fa-dial-high" :class="textColor"></i>
                </div>
                <span class="fs-sm">{{ item.attributes.level }}</span>
              </div>
            </div>
            <div v-if="item.attributes._category != 'YouTube'" class="col">
              <div class="rounded text-center w-100 h-100 p-2">
                <p class="fs-sm mb-1 text-uppercase">Updated</p>
                <div
                  class="icon-box-media rounded-circle mx-auto"
                  :class="bgFadedColor"
                >
                  <i
                    class="fa-thin fa-calendar-lines-pen"
                    :class="textColor"
                  ></i>
                </div>
                <span class="fs-sm">{{ lastUpdated }}</span>
              </div>
            </div>
          </div>
          <div class="row g-2">
            <div class="col text-center">
              <button class="btn btn-link p-0" :class="disabled_class">
                <span class="fa-stack" style="vertical-align: top;">
                  <i class="fa-thin fa-circle fa-stack-2x"></i>
                  <i
                    class="fa-thin fa-thumbs-up px-2 fa-stack-1x"
                    @click.once="giveFeedback('RELEVANT')"
                  ></i>
                </span>
              </button>
              <button class="btn btn-link p-0" :class="disabled_class">
                <span class="fa-stack" style="vertical-align: top;">
                  <i class="fa-thin fa-circle fa-stack-2x"></i>
                  <i
                    class="fa-thin fa-thumbs-down px-2 fa-stack-1x"
                    @click.once="giveFeedback('NOT_RELEVANT')"
                  ></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import ContentIcon from '@/components/ContentIcon.vue'
import KendraService from '@/services/KendraService.js'
import config from '@/config.js'
export default {
  name: 'ContentCard',
  components: {
    ContentIcon
  },
  props: {
    item: Object,
    query: String
  },
  methods: {
    showHighlights(excerpt) {
      let text = excerpt.Text
      let count = 0
      let offset = 13
      for (let h of excerpt.Highlights) {
        text =
          text.substr(0, h.EndOffset + count * offset) +
          '</mark>' +
          text.substr(h.EndOffset + count * offset)
        text =
          text.substr(0, h.BeginOffset + count * offset) +
          '<mark>' +
          text.substr(h.BeginOffset + count * offset)
        count += 1
      }
      return text
    },
    addTag(tag) {
      this.$emit('add_tag', tag)
    },
    calculateDaysAgo(diff) {
      let num_days_ago = parseInt(diff / 86400000)
      if (num_days_ago < 1) {
        this.days_ago = ' today'
      } else if (num_days_ago == 1) {
        this.days_ago = ' yesterday'
      } else {
        this.days_ago = num_days_ago + ' days ago'
      }
    },
    defaultImage(e) {
      // If a 404 (or other error) is returned, display default image
      e.target.src = '/images/aws-logo.png'
    },
    giveFeedback(relevance) {
      this.feedback = JSON.stringify({
        query_id: this.query,
        result_id: this.item.id,
        relevance: relevance
      })
      KendraService.feedback(this.feedback).catch(err => {
        console.log(err)
      })
      this.disabled_class = 'disabled'
      this.$emit('show_toast')
    }
  },
  computed: {
    lastUpdatedClass() {
      let now = Date.now()
      let updated = Date.parse(this.item.attributes._last_updated_at)
      if (now - updated < 2592000000) {
        this.calculateDaysAgo(now - updated)
        return { 'd-table badge bg-primary': true }
      } else {
        return { invisible: true }
      }
    },
    lastUpdated() {
      return this.item.attributes._last_updated_at.split('T')[0]
    },
    bgFadedColor() {
      return { [config[this.item.attributes._category].bg_faded]: true }
    },
    bgSolidColor() {
      return { [config[this.item.attributes._category].bg_solid]: true }
    },
    bgButton() {
      return { [config[this.item.attributes._category].button]: true }
    },
    textColor() {
      return { [config[this.item.attributes._category].color]: true }
    },
    launchText() {
      return config[this.item.attributes._category].launch_text
    },
    authors() {
      if (this.item.attributes._authors.length > 1) {
        return this.item.attributes._authors.join(', ')
      } else {
        return this.item.attributes._authors[0]
      }
    },
    tags() {
      // Returns tags with 'aws' removed
      let tags = this.item.tags
      let index = tags.indexOf('aws')
      if (index > -1) {
        tags.splice(index, 1)
      }
      return tags
    }
  },
  data() {
    return {
      added: false,
      days_ago: '',
      disabled_class: ''
    }
  }
}
</script>
