<template>
  <span class="fa-stack fa-xl" :class="iconColor">
    <i class="fa-thin fa-circle fa-stack-2x"></i>
    <i class="fa-stack-1x" :class="iconType"></i>
  </span>
</template>

<script>
import config from '@/config.js'
export default {
  name: 'ContentIcon',
  props: {
    content: String
  },
  computed: {
    iconType() {
      return { [config[this.content].icon]: true }
    },
    iconColor() {
      return { [config[this.content].color]: true }
    }
  }
}
</script>

<style></style>
