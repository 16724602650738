import axios from 'axios'

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_GATEWAY_ENDPOINT,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json; charset=UTF8'
  }
})

export default {
  search(body) {
    return apiClient.post('/search', btoa(body))
  },
  feedback(body) {
    return apiClient.post('/feedback', btoa(body))
  }
}
