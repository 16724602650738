<template>
  <!-- Accordion item -->
  <div class="accordion-item">
    <h2 class="accordion-header" :id="'heading' + index">
      <button
        class="accordion-button collapsed"
        type="button"
        data-bs-toggle="collapse"
        :data-bs-target="'#collapse' + index"
        aria-expanded="false"
        :aria-controls="'collapse' + index"
      >
        {{ item.title }}
      </button>
    </h2>
    <div
      class="accordion-collapse collapse"
      :aria-labelledby="'heading' + index"
      data-bs-parent="#accordionFAQ"
      :id="'collapse' + index"
    >
      <div class="accordion-body">
        {{ item.attributes.description }}
        <a :href="item.url" target="_blank" :data-kendra-token="item.token"
          >Read more</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FAQAccordion',
  props: {
    item: Object,
    index: Number
  }
}
</script>
